<template>
  <generic-indicator-card
      :toDonut="toDonut"
      :donutTotal="currency(toDonut.total,true)"
      :isDonutCurrencies="true"
      :from="from"
      :to="to"
      :activate-modal="true"
  >
    <template #header>
      <h4 v-b-tooltip.hover.bottom="capitalize($t('potentialTurnover'))">
        {{ $t('Sales') }}
        <br/>
        {{ currency(toDonut.total, true) }}
        <br/>
        <div class="text-truncate">{{ capitalize($t('potentialTurnover')) }}</div>
      </h4>
    </template>

    <template #indicators>
      <b-row>

        <!--          Total billed-->
        <b-col
            md="6"
            v-b-tooltip.hover.bottom="capitalize($t('invoiced:')) + ' '+ currency(currentBilled, true) + ' /' +  currency(workflow.potentialTurnover, true)"
        >
          <label>
            {{ capitalize($t('invoiced:')) }}&nbsp;{{ currency(currentBilled, true) }} / {{
              currency(workflow.potentialTurnover, true)
            }}
          </label>
          <b-progress
              v-model="currentBilled"
              :max="workflow.potentialTurnover"
              animated
              variant="primary"
              :class="'progress-bar-primary'"
          />
        </b-col>

        <!--          Total ordered-->
        <b-col
            md="6"
            v-b-tooltip.hover.bottom="capitalize($t('ordered:')) + ' ' + currency(currentOrdered, true) + ' /' +  currency(workflow.potentialTurnover, true)"
        >
          <label>
            {{ capitalize($t('ordered:')) }}&nbsp;{{ currency(currentOrdered, true) }} / {{
              currency(workflow.potentialTurnover, true)
            }}
          </label>
          <b-progress
              v-model="currentOrdered"
              :max="workflow.potentialTurnover"
              animated
              variant="primary"
              :class="'progress-bar-primary'"
          />
        </b-col>

        <!--          paid ratio-->
        <!--          <b-col-->
        <!--              md="6"-->
        <!--              v-b-tooltip.hover.bottom="capitalize($t('ordered:')) + ' ' + currency(currentOrdered, true) + ' /' +  currency(workflow.potentialTurnover, true)"-->
        <!--          >-->
        <!--            <label>-->
        <!--              {{ capitalize($t('ordered:')) }}&nbsp;{{ currency(currentOrdered, true) }} / {{-->
        <!--                currency(workflow.potentialTurnover, true)-->
        <!--              }}-->
        <!--            </label>-->
        <!--            <b-progress-->
        <!--                v-model="currentOrdered"-->
        <!--                :max="workflow.potentialTurnover"-->
        <!--                animated-->
        <!--                variant="primary"-->
        <!--                :class="'progress-bar-primary'"-->
        <!--            />-->
        <!--          </b-col>-->


      </b-row>
    </template>

    <template #indicatorDetails>
      <sales-indicator-details
      :offers="currentTurnover.offerEntities"
      :outgoingInvoices="currentTurnover.outgoingInvoiceEntities"
      />
    </template>

  </generic-indicator-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'
import { useIndicators } from '../../../../../components/indicators/useIndicators'

import i18n from '@/libs/i18n'
import GenericIndicatorCard from './_GenericCard'
import SalesIndicatorDetails from '@/components/indicatorDetails/SalesIndicators'

export default {
  components: {
    GenericIndicatorCard,
    SalesIndicatorDetails
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String
    },
    to: {
      type: String
    },
    selectedPhases: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentOrdered = computed(() => {
      return currentTurnover.value.order
    })

    const currentBilled = computed(() => {
      return currentTurnover.value.outgoingInvoice
    })

    const currentTurnover = computed(() => {
      return getCurrentTurnover(
          props.workflow,
          props.from,
          props.to,
          props.selectedPhases)
    })

    const toDonut = computed(() => {
      return {
        labels: [
          capitalize(i18n.tc('offer', 2)),
          capitalize(i18n.tc('order', 2)),
          capitalize(i18n.tc('invoice', 2)),
        ],
        values: [
          currentTurnover.value.offer,
          currentTurnover.value.order,
          currentTurnover.value.outgoingInvoice,
        ],
        total: currentTurnover.value.total
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      getCurrentTurnover
    } = useIndicators()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data

      // Computed
      currentBilled,
      currentOrdered,
      currentTurnover,
      toDonut,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>