<template>
  <div>
    <incoming-invoice-home
        v-if="workflow"
        :incomingInvoices="workflow.incomingInvoices"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import incomingInvoiceHome from '@/components/home/incomingInvoice/IncomingInvoice'

export default {
  components: {
    incomingInvoiceHome
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>