<template>
  <generic-indicator-card
      :toDonut="toDonut"
      :donutTotal="toDonut.total.toFixed(1) + ' ' + $tc('hour', toDonut.total, {hour: toDonut.total})"
      :isDonutHours="true"
      :from="from"
      :to="to"
      :activate-modal="true"
  >
    <template #header>
      <h4 v-b-tooltip.hover.bottom="capitalize($t('potentialExpenses'))">
        {{ $t('HumanResources') }}
        <br/>
        <div class="text-truncate">
          {{
            toDonut.total.toFixed(1) + ' ' + $tc('hour', toDonut.total, { hour: toDonut.total }) + ' ' + $t('inTotal')
          }}
        </div>
      </h4>
    </template>

    <template #indicators>
      <b-row>

        <!--          Time spent-->
        <b-col
            md="6"
            v-b-tooltip.hover.bottom="capitalize($t('timeSpent:')) + ' '+ number(currentTimeSpent) + ' /' + number(workflow.potentialTimeSpent==0?currentTimeSpent:workflow.potentialTimeSpent)"
        >
          <label>
            {{ capitalize($t('timeSpent:')) }}&nbsp;{{ number(currentTimeSpent) }} / {{
              number(workflow.potentialTimeSpent == 0 ? currentTimeSpent : workflow.potentialTimeSpent)
            }}
          </label>
          <b-progress
              v-model="currentTimeSpent"
              :max="workflow.potentialTimeSpent==0?currentTimeSpent:workflow.potentialTimeSpent"
              animated
              variant="primary"
              :class="'progress-bar-primary'"
          />
        </b-col>

        <!--          Duration-->
        <!--          <b-col-->
        <!--              v-if="workflow.closingDate != null"-->
        <!--              md="6"-->
        <!--              v-b-tooltip.hover.bottom="capitalize($t('budget:')) + ' '+ currency(currentPurchased, true) + ' /' +  currency(workflow.potentialExpenses, true)"-->
        <!--          >-->
        <!--            <label>-->
        <!--              {{ capitalize($t('budget:')) }}&nbsp;{{ currency(currentPurchased, true) }} / {{-->
        <!--                currency(workflow.potentialExpenses, true)-->
        <!--              }}-->
        <!--            </label>-->
        <!--            <b-progress-->
        <!--                v-model="currentPurchased"-->
        <!--                :max="workflow.potentialExpenses"-->
        <!--                animated-->
        <!--                variant="primary"-->
        <!--                :class="'progress-bar-primary'"-->
        <!--            />-->
        <!--          </b-col>-->


      </b-row>
    </template>

    <template #indicatorDetails>
      <time-spent-by-users
          :timeSpentByUsers="currentTimeSpentByUsers"
      />
    </template>

  </generic-indicator-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, number } from '@/utils/filter'

import GenericIndicatorCard from './_GenericCard'
import TimeSpentByUsers from '@/components/indicatorDetails/TimeSpentByUsers'
import moment from 'moment'

export default {
  components: {
    GenericIndicatorCard,
    TimeSpentByUsers,
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String
    },
    to: {
      type: String
    },
    selectedPhases: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentTimeSpentByUsers = computed(() => {
      let currentTimeSpentByUsersLocal = []

      let fromLocal = moment(props.from + ' 00:00:00')
      let toLocal = moment(props.to + ' 23:59:59')
      let concernedEvents = props.workflow.events.filter(e => {
        return (
            moment(e.startDate) >= fromLocal &&
            moment(e.startDate) <= toLocal &&
            moment(e.endDate) >= fromLocal &&
            moment(e.endDate) <= toLocal &&
            (
                props.selectedPhases.length == 0 ||
                (
                    props.selectedPhases.length == 1 &&
                    e.phase != null &&
                    props.selectedPhases.some(pId => pId == e.phase.id)
                )
            )
        )
      })
      concernedEvents.forEach(e => {
        let hours = moment.duration(moment(e.endDate).diff(moment(e.startDate))).asHours()
        let user = e.logs[0].by

        if (currentTimeSpentByUsersLocal.some(tsby => tsby.name == user._display)) {
          currentTimeSpentByUsersLocal.find(tsby => tsby.name == user._display).duration += hours
        } else {
          currentTimeSpentByUsersLocal.push({
            name: user._display,
            duration: hours
          })
        }
      })

      return currentTimeSpentByUsersLocal
    })

    const currentTimeSpent = computed(() => {
      let duration = 0
      currentTimeSpentByUsers.value.forEach(tsby => duration += tsby.duration)

      return duration
    })

    const toDonut = computed(() => {
      let output = {
        labels: [],
        values: [],
        total: currentTimeSpent.value
      }

      currentTimeSpentByUsers.value.forEach(tsby => {
        output.labels.push(tsby.name)
        output.values.push(tsby.duration)
      })

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      number,

      // Data

      // Computed
      currentTimeSpentByUsers,
      currentTimeSpent,
      toDonut

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>