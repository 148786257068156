import { capitalize, currency, percent } from '@/utils/filter'
import { getCurrentFinancialYear } from '@/utils/utils'

import store from '@/store'
import i18n from '@/libs/i18n'
import useAPI from '@/utils/useAPI'
import moment from 'moment'
import { computed } from '@vue/composition-api'

export const useIndicators = () => {
  const { getUserContract } = useAPI()

  // ==================================================================
  // =============                 SALES                  =============
  // ==================================================================
  const populateTurnoverWithOffers = (turnover, offers, from, to, selectedPhasesIds = null) => {
    // console.log(offers.length)

    let concernedOffers = offers.filter(o => {
      return (moment(from) <= moment(o.billingDate) &&
        (moment(o.billingDate) <= moment(to)) &&
        o.isCanceled == false &&
        o.isDeclined == false &&
        (
          selectedPhasesIds == null ||
          selectedPhasesIds.length == 0 ||
          (
            selectedPhasesIds.length == 1 &&
            o.phase != null &&
            selectedPhasesIds.some(pId => pId == o.phase.id)
          )
        )
      )
    })
    concernedOffers.forEach(o => {
      if (o._isValidated) {
        o.products.forEach(p => {
          if (parseFloat(p._remaining) > 0) {
            turnover.offer += parseFloat(p._remaining) * parseFloat(p.unitPrice)
            turnover.total += parseFloat(p._remaining) * parseFloat(p.unitPrice)
          }

          if (p.childs.length) {
            p.childs.forEach(invoicedProduct => {
              turnover.outgoingInvoice += parseFloat(invoicedProduct.preTaxAmount)
              turnover.total += parseFloat(invoicedProduct.preTaxAmount)

              populateProducts(turnover, 'invoicedProducts', p)
            })
          } else {
            populateProducts(turnover, 'offeredProducts', p)
          }
        })

      } else {
        turnover.offer += o.preTaxAmount
        turnover.total += o.preTaxAmount
      }

      turnover.offerEntities.push(o)
    })
  }

  const populateTurnoverWithOutgoingInvoices = (turnover, outgoingInvoices, from, to, selectedPhasesIds = null) => {
    let concernedOutgoingInvoices = outgoingInvoices.filter(o => {
      return (
          from <= moment(o.billingDate) &&
          (moment(o.billingDate) <= to)
        ) &&
        (
          selectedPhasesIds == null ||
          selectedPhasesIds.length == 0 ||
          (
            selectedPhasesIds.length == 1 &&
            o.phase != null &&
            selectedPhasesIds.some(pId => pId == o.phase.id)
          )
        )
    })
    concernedOutgoingInvoices.forEach(o => {
      o.products.forEach(p => {
        if (p.parent == null) {
          turnover.outgoingInvoice += p.preTaxAmount
          turnover.total += p.preTaxAmount

          populateProducts(turnover, 'invoicedProducts', p)
        }
      })
      turnover.outgoingInvoiceEntities.push(o)
    })
  }

  const getCurrentTurnover = (workflow, from, to, selectedPhasesIds) => {
    let fromLocal = moment(from + ' 00:00:00')
    let toLocal = moment(to + ' 23:59:59')

    let currentTurnoverLocal = {
      offer: 0,
      order: 0,
      outgoingInvoice: 0,
      total: 0,
      offerEntities: [],
      outgoingInvoiceEntities: [],
    }

    // populateTurnoverWithOffers(
    //   currentTurnoverLocal,
    //   workflow.offers,
    //   fromLocal,
    //   toLocal,
    //   selectedPhasesIds)

    populateTurnoverWithOutgoingInvoices(
      currentTurnoverLocal,
      workflow.outgoingInvoices,
      fromLocal,
      toLocal,
      selectedPhasesIds)

    // console.log(currentTurnoverLocal)
    return currentTurnoverLocal
  }

  const getInvoicedCurrentSales = (workflow, from, to, selectedPhasesIds) => {
    let invoicedCurrentSales = getCurrentTurnover(workflow, from, to, selectedPhasesIds)

    return invoicedCurrentSales.outgoingInvoice
  }

  const getTurnoverByFinancialYear = (offers, outgoingInvoices) => {
    let dateStart = moment(getCurrentFinancialYear().start)
    let dateEnd = moment(getCurrentFinancialYear().end)

    // return getTurnoverByMonths(offers, outgoingInvoices, dateStart, dateEnd)
    return getTurnoverByMonths([], outgoingInvoices, dateStart, dateEnd)
    // let turnoverByFinancialYear = []
    // let dateStart = moment(getCurrentFinancialYear().start)
    // let dateEnd = moment(getCurrentFinancialYear().end)
    // while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    //   let currentMonthStart = moment(dateStart.format('YYYY-MM') + '-01' + ' 00:00:00')
    //   let currentMonthEnd = moment(dateStart.endOf('month').format('YYYY-MM-DD') + ' 23:59:59')
    //   let currentTurnoverLocal = {
    //     offer: 0,
    //     order: 0,
    //     outgoingInvoice: 0,
    //     total: 0,
    //     offerEntities: [],
    //     outgoingInvoiceEntities: [],
    //   }
    //   populateTurnoverWithOffers(
    //     currentTurnoverLocal,
    //     offers,
    //     currentMonthStart,
    //     currentMonthEnd)
    //   populateTurnoverWithOutgoingInvoices(currentTurnoverLocal, outgoingInvoices, currentMonthStart, currentMonthEnd)
    //
    //   turnoverByFinancialYear.push({
    //     month: dateStart.format('YYYY-MM'),
    //     turnover: currentTurnoverLocal
    //   })
    //
    //   dateStart.add(1, 'month')
  }

  // const getTurnoverByMonths = (offers, outgoingInvoices, start, end) => {
  const getTurnoverByMonths = (offers, outgoingInvoices, start, end) => {
    // console.log(offers)
    let turnoverByMonths = []

    let dateStart = moment(start)
    let dateEnd = moment(end)

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      let currentMonthStart = moment(dateStart.format('YYYY-MM') + '-01' + ' 00:00:00')
      let currentMonthEnd = moment(dateStart.endOf('month').format('YYYY-MM-DD') + ' 23:59:59')
      let currentTurnoverLocal = {
        offer: 0,
        order: 0,
        outgoingInvoice: 0,
        total: 0,
        offerEntities: [],
        outgoingInvoiceEntities: [],
        products: {
          offeredProducts: [],
          offeredProductsTotal: 0,
          invoicedProducts: [],
          invoicedProductsTotal: 0
        }
      }
      // console.log("+1")
      // populateTurnoverWithOffers(
      //   currentTurnoverLocal,
      //   offers,
      //   currentMonthStart,
      //   currentMonthEnd)

      populateTurnoverWithOffers(currentTurnoverLocal, offers, currentMonthStart, currentMonthEnd)
      populateTurnoverWithOutgoingInvoices(currentTurnoverLocal, outgoingInvoices, currentMonthStart, currentMonthEnd)

      turnoverByMonths.push({
        month: dateStart.format('YYYY-MM'),
        turnover: currentTurnoverLocal
      })

      dateStart.add(1, 'month')
    }

    // console.log(turnoverByMonths)

    return turnoverByMonths
  }

  const averageMonthly = (turnoverByMonths) => {
    let averageMonthly = 0
    turnoverByMonths.forEach(tm => {
      averageMonthly += tm.turnover.total
    })

    return averageMonthly / turnoverByMonths.length
  }

  // ==================================================================
  // =============               PURCHASES                =============
  // ==================================================================
  const populatePurchasesWithOrders = (purchases, orders, from, to, selectedPhasesIds = null) => {
    let concernedOrders = orders.filter(o => {
      return (
        from <= moment(o.logs[0].at) &&
        (moment(o.logs[0].at) <= to)
      ) && (
        selectedPhasesIds == null ||
        selectedPhasesIds.length == 0 ||
        (
          selectedPhasesIds.length == 1 &&
          o.phase != null &&
          selectedPhasesIds.some(pId => pId == o.phase.id)
        )
      )

    })
    concernedOrders.forEach(o => {
      if (o.number != null) {
        if (
          o.signedDocumentNumber != null ||
          o.signedDocumentDate != null ||
          o.signedDocument != null
        ) {
          // Order ordered
          o.products.forEach(p => {
            if (p._remaining > 0) {
              purchases.orderOrdered += parseFloat(p._remaining) * parseFloat(p.unitPrice)
              purchases.total += parseFloat(p._remaining) * parseFloat(p.unitPrice)
            }

            if (p.childs.length) {
              p.childs.forEach(invoicedProduct => {
                purchases.incomingInvoices += parseFloat(invoicedProduct.preTaxAmount)
                purchases.total += parseFloat(invoicedProduct.preTaxAmount)

                populateProducts(purchases, 'invoicedProducts', p)
              })
            } else {
              populateProducts(purchases, 'orderedProducts', p)

            }
          })

          if ('types' in purchases) {
            purchases.types.ordered.orders.push(o)
            purchases.types.ordered.ordersTotal += parseFloat(o.preTaxAmount)
            purchases.types.ordered.total += parseFloat(o.preTaxAmount)
          }

        } else {
          // Order approuved not ordered
          purchases.orderApprovedNotOrderer += parseFloat(o.preTaxAmount)
          purchases.total += parseFloat(o.preTaxAmount)
        }

        purchases.orderEntities.push(o)
      }
    })
  }

  const populateProducts = (purchases, type, p) => {
    // console.log(purchases)
    if (!('products' in purchases)) {
      return
    }

    let articleExistIndex = purchases.products[type].findIndex(pp => pp.general.article == p.article.name)
    if (articleExistIndex == -1) {
      purchases.products[type].push({
        general: {
          article: p.article.name,
          quantity: parseFloat(p.quantity),
          preTaxAmount: parseFloat(p.preTaxAmount),
          percent: null,
          unitPrices: [(parseFloat(p.preTaxAmount) / parseFloat(p.quantity))]
        },
        detail: [{
          article: p.article.name,
          quantity: parseFloat(p.quantity),
          preTaxAmount: parseFloat(p.preTaxAmount),
          // entity: o,
          entityType: 'order'
        }]
      })

      purchases.products[(type + 'Total')] += parseFloat(p.preTaxAmount)
    } else {
      purchases.products[type][articleExistIndex].general.quantity += parseFloat(p.quantity)
      purchases.products[type][articleExistIndex].general.preTaxAmount += parseFloat(p.preTaxAmount)
      purchases.products[type][articleExistIndex].general.unitPrices.push(parseFloat(p.preTaxAmount) / parseFloat(p.quantity))
      purchases.products[type][articleExistIndex].detail.push({
        article: p.article.name,
        quantity: parseFloat(p.quantity),
        preTaxAmount: parseFloat(p.preTaxAmount),
        // entity: o,
        entityType: 'order'
      })
      purchases.products[(type + 'Total')] += parseFloat(p.preTaxAmount)
    }
  }

  const populatePurchasesWithIncomingInvoices = (purchases, incomingInvoices, from, to, selectedPhasesIds = null) => {
    let concernedIncomingInvoices = incomingInvoices.filter(i => {
      return (
        from <= moment(i.billingDate) && (moment(i.billingDate) <= to)
      ) && (
        selectedPhasesIds == null ||
        selectedPhasesIds.length == 0 ||
        (
          selectedPhasesIds.length == 1 &&
          i.phase != null &&
          selectedPhasesIds.some(pId => pId == i.phase.id)
        )
      )
    })
    concernedIncomingInvoices.forEach(i => {
      i.products.forEach(p => {
        if (p.parent == null) {
          purchases.incomingInvoices += p.preTaxAmount
          purchases.total += p.preTaxAmount

          populateProducts(purchases, 'invoicedProducts', p)
        }
      })

      purchases.incomingInvoiceEntities.push(i)

      if ('types' in purchases) {
        if (i.type == 'production') {
          purchases.types.invoiced.productionExpenses.push(i)
          purchases.types.invoiced.productionExpensesTotal += parseFloat(i.preTaxAmount)
          purchases.types.invoiced.total += parseFloat(i.preTaxAmount)
        } else if (i.type == 'toll') {
          purchases.types.invoiced.transportExpenses.push(i)
          purchases.types.invoiced.transportExpensesTotal += parseFloat(i.preTaxAmount)
          purchases.types.invoiced.total += parseFloat(i.preTaxAmount)
        } else if (i.type == 'catering') {
          purchases.types.invoiced.cateringExpenses.push(i)
          purchases.types.invoiced.cateringExpensesTotal += parseFloat(i.preTaxAmount)
          purchases.types.invoiced.total += parseFloat(i.preTaxAmount)
        } else if (i.type == 'accommodation') {
          purchases.types.invoiced.accomodationExpenses.push(i)
          purchases.types.invoiced.accomodationExpensesTotal += parseFloat(i.preTaxAmount)
          purchases.types.invoiced.total += parseFloat(i.preTaxAmount)
        } else {
          purchases.types.invoiced.classicExpenses.push(i)
          purchases.types.invoiced.classicExpensesTotal += parseFloat(i.preTaxAmount)
          purchases.types.invoiced.total += parseFloat(i.preTaxAmount)
        }
      }

    })
  }

  const populatePurchasesWithEvents = (purchases, events, from, to, selectedPhasesIds = null) => {
    let concernedEvents = events.filter(e => {
      return (
        moment(e.startDate) >= from &&
        moment(e.startDate) <= to &&
        moment(e.endDate) >= from &&
        moment(e.endDate) <= to &&
        (
          selectedPhasesIds == null ||
          selectedPhasesIds.length == 0 ||
          (
            selectedPhasesIds.length == 1 &&
            e.phase != null &&
            selectedPhasesIds.some(pId => pId == e.phase.id)
          )
        )
      )
    })
    concernedEvents.forEach(e => {
      let minutes = moment.duration(moment(e.endDate).diff(moment(e.startDate))).asMinutes()
      // let user = store.getters['user/getUser'](e.logs[0].by.id)
      let user = e.logs[0].by

      let costPerMinutes = 0
      if (getUserContract(user, e.startDate) != null) {
        costPerMinutes = getUserContract(user, e.startDate)._costPerMinute
      }
      let eventCost = costPerMinutes * minutes

      purchases.time += eventCost
      purchases.total += eventCost

      if (e.type == 'trip' && e.isTripWithPersonalVehicle) {
        purchases.types.invoiced.mileageAllowances.push(e)
        purchases.types.invoiced.mileageAllowancesTotal += e._mileageAllowanceCost
        purchases.types.invoiced.total += e._mileageAllowanceCost
      }

    })
  }

  const populatePurchasesWithSalaries = (purchases, salaries, from, to) => {
    let concernedSalaries = salaries.filter(s => {
      return (
        from <= moment(s.month) && (moment(s.month) <= to)
      )
    })
    concernedSalaries.forEach(s => {
      purchases.types.invoiced.salaries.push(s)
      purchases.types.invoiced.salariesTotal += parseFloat(s.net)
      purchases.types.invoiced.total += parseFloat(s.net)
    })
  }

  const populatePurchasesWithCharges = (purchases, charges, from, to) => {
    let concernedCharges = charges.filter(c => {
      return (
        from <= moment(c.month) && (moment(c.month) <= to)
      )
    })
    concernedCharges.forEach(c => {
      purchases.types.invoiced.charges.push(c)
      purchases.types.invoiced.chargesTotal += parseFloat(c.preTaxAmount)
      purchases.types.invoiced.total += parseFloat(c.preTaxAmount)
    })
  }

  const getCurrentPurchases = (
    workflow,
    from,
    to,
    selectedPhasesIds = null,
    withEvents = true
  ) => {
    let fromLocal = moment(from + ' 00:00:00')
    let toLocal = moment(to + ' 23:59:59')

    let currentPurchasesLocal = {
      orderApprovedNotOrderer: 0,
      orderOrdered: 0,
      incomingInvoices: 0,
      time: 0,
      total: 0,
      orderEntities: [],
      incomingInvoiceEntities: [],
    }

    populatePurchasesWithOrders(
      currentPurchasesLocal,
      workflow.orders,
      fromLocal,
      toLocal,
      selectedPhasesIds
    )

    populatePurchasesWithIncomingInvoices(
      currentPurchasesLocal,
      workflow.incomingInvoices,
      fromLocal,
      toLocal,
      selectedPhasesIds)

    if (withEvents) {
      populatePurchasesWithEvents(
        currentPurchasesLocal,
        workflow.events,
        fromLocal,
        toLocal,
        selectedPhasesIds)

    }

    return currentPurchasesLocal
  }

  const getInvoicedCurrentPurchase = (workflow, from, to, selectedPhasesIds) => {
    let currentPurchases = getCurrentPurchases(workflow, from, to, selectedPhasesIds)
    return currentPurchases.incomingInvoices + currentPurchases.time
  }

  const getPurchasesByFinancialYear = (orders, incomingInvoices, events) => {
    // let purchasesByFinancialYear = []
    let dateStart = moment(getCurrentFinancialYear().start)
    let dateEnd = moment(getCurrentFinancialYear().end)

    return getPurchasesByMonths(orders, incomingInvoices, events, dateStart, dateEnd)
    // while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    //   let currentMonthStart = moment(dateStart.format('YYYY-MM') + '-01' + ' 00:00:00')
    //   let currentMonthEnd = moment(dateStart.endOf('month').format('YYYY-MM-DD') + ' 23:59:59')
    //   let currentPurchasesLocal = {
    //     orderApprovedNotOrderer: 0,
    //     orderOrdered: 0,
    //     incomingInvoices: 0,
    //     time: 0,
    //     total: 0,
    //     orderEntities: [],
    //     incomingInvoiceEntities: [],
    //   }
    //
    //   populatePurchasesWithOrders(currentPurchasesLocal, orders, currentMonthStart, currentMonthEnd)
    //   populatePurchasesWithIncomingInvoices(currentPurchasesLocal, incomingInvoices, currentMonthStart, currentMonthEnd)
    //   populatePurchasesWithEvents(currentPurchasesLocal, events, currentMonthStart, currentMonthEnd)
    //
    //   purchasesByFinancialYear.push({
    //     month: dateStart.format('YYYY-MM'),
    //     purchases: currentPurchasesLocal
    //   })
    //
    //   dateStart.add(1, 'month')
    // }
    //
    // return purchasesByFinancialYear
  }

  const getPurchasesByMonths = (
    orders,
    incomingInvoices,
    events,
    salaries,
    socialSecurityContributions,
    start,
    end
  ) => {
    let purchasesByMonths = []

    let dateStart = moment(start)
    let dateEnd = moment(end)
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      let currentMonthStart = moment(dateStart.format('YYYY-MM') + '-01' + ' 00:00:00')
      let currentMonthEnd = moment(dateStart.endOf('month').format('YYYY-MM-DD') + ' 23:59:59')
      let currentPurchasesLocal = {
        orderApprovedNotOrderer: 0,
        orderOrdered: 0,
        incomingInvoices: 0,
        time: 0,
        total: 0,
        orderEntities: [],
        incomingInvoiceEntities: [],
        products: {
          orderedProducts: [],
          orderedProductsTotal: 0,
          invoicedProducts: [],
          invoicedProductsTotal: 0
        },
        types: {
          ordered: {
            orders: [],
            ordersTotal: 0,
            // Add salaries, etc
            total: 0,
          },
          invoiced: {
            salaries: [],
            salariesTotal: 0,
            charges: [],
            chargesTotal: 0,
            classicExpenses: [],
            classicExpensesTotal: 0,
            transportExpenses: [],
            transportExpensesTotal: 0,
            cateringExpenses: [],
            cateringExpensesTotal: 0,
            accomodationExpenses: [],
            accomodationExpensesTotal: 0,
            productionExpenses: [],
            productionExpensesTotal: 0,
            mileageAllowances: [],
            mileageAllowancesTotal: 0,
            total: 0,
          }
        }
      }

      populatePurchasesWithOrders(currentPurchasesLocal, orders, currentMonthStart, currentMonthEnd)
      populatePurchasesWithIncomingInvoices(currentPurchasesLocal, incomingInvoices, currentMonthStart, currentMonthEnd)
      populatePurchasesWithEvents(currentPurchasesLocal, events, currentMonthStart, currentMonthEnd)
      populatePurchasesWithSalaries(currentPurchasesLocal, salaries, currentMonthStart, currentMonthEnd)
      populatePurchasesWithCharges(currentPurchasesLocal, socialSecurityContributions, currentMonthStart, currentMonthEnd)

      purchasesByMonths.push({
        month: dateStart.format('YYYY-MM'),
        purchases: currentPurchasesLocal
      })
      // console.log({
      //   month: dateStart.format('YYYY-MM'),
      //   purchases: currentPurchasesLocal
      // })

      dateStart.add(1, 'month')
    }

    return purchasesByMonths
  }

  const purchasesAverageMonthly = (purchasesByMonths) => {
    let purchasesAverageMonthly = 0
    // console.log(purchasesByMonths)
    purchasesByMonths.forEach(tm => {
      purchasesAverageMonthly += tm.purchases.total
    })

    return purchasesAverageMonthly / purchasesByMonths.length
  }

  return {
    // Sales
    getCurrentTurnover,
    getInvoicedCurrentSales,
    getTurnoverByFinancialYear,
    getTurnoverByMonths,
    averageMonthly,

    // Purchases
    getCurrentPurchases,
    getInvoicedCurrentPurchase,
    getPurchasesByFinancialYear,
    getPurchasesByMonths,
    purchasesAverageMonthly,
  }
}