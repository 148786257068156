<template>
  <div>
    <div
        v-for="(timeByUser,index) in timeSpentByUsers"
        :key="index"
        class="d-flex justify-content-between"
        :class="index === timeSpentByUsers.length - 1 ? 'mb-0':'mb-1'"
    >
      <div class="series-info d-flex align-items-center">
        <span class="font-weight-bolder ml-75 mr-25">{{ timeByUser.name }}</span>
        <span> - {{ getTime(timeByUser)|duration('humanize') }}</span>
      </div>
      <div>
        <span>{{ percent(getPercent(timeByUser), 1) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { percent } from '@/utils/filter'

import moment from 'moment'

export default {
  components: {},
  props: {
    timeSpentByUsers: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const totalTime = computed(() => {
      let total = 0

      props.timeSpentByUsers.forEach(t => total += t.duration)

      return total
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getTime = (timeSpent) => {
      return moment.duration(timeSpent.duration, 'hours')
    }

    const getPercent = (timeSpent) => {
      return timeSpent.duration * 100 / totalTime.value
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      percent,

      // Data

      // Computed

      // Methods
      getTime,
      getPercent,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>