<template>
  <div>
    <b-card
        :class="{'cursor-pointer': activateModal}"
        @click="activateModal?(modalDetailsShow = true):false"
    >
      <b-row>

        <!--      Left col-->
        <b-col md="6">
          <slot name="header"/>

          <div>
            {{ $t('from:') }} {{ from|moment('L') }}
            <br/>
            {{ $t('to:') }} {{ to|moment('L') }}
          </div>
        </b-col>

        <!--      Right chart-->
        <b-col md="6">
          <donut
              @click.native.stop=""
              :labels="toDonut.labels"
              :values="toDonut.values"
              :total="donutTotalLocal"
              :isHours="isDonutHours"
              :isCurrencies="isDonutCurrencies"
          />
        </b-col>

        <!--      Objective-->
        <b-col cols="12"
               class="mt-2">

          <slot name="indicators"/>
        </b-col>

      </b-row>
    </b-card>


    <b-modal
        v-if="activateModal"
        v-model="modalDetailsShow"
        :title="$t('Details')"
        :ok-title="$t('Close')"
        ok-only
        size="lg"
    >
      <slot name="indicatorDetails"/>
    </b-modal>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'

import Donut from '@/components/chart/Donut'

export default {
  components: {
    Donut
  },
  props: {
    toDonut: {
      type: Object,
      default: () => {}
    },
    donutTotal: {
      type: String,
      default: ''
    },
    isDonutHours: {
      type: Boolean,
      default: false
    },
    isDonutCurrencies: {
      type: Boolean,
      default: false
    },
    from: {
      type: String
    },
    to: {
      type: String
    },
    activateModal: {
      type: Boolean,
      default: false
    }

  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const modalDetailsShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const donutTotalLocal = computed(() => {
      if (props.donutTotal != '') {
        return props.donutTotal
      } else {
        return props.toDonut.total
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      modalDetailsShow,

      // Computed
      donutTotalLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>