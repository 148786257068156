<template>
  <div class="d-flex justify-content-between">
    <div class="m-auto pr-1">
      <b-avatar
          variant="light-primary"
          :text="userAvatarText(activity.assignedTo)"
          v-b-tooltip.hover.right="activity.assignedTo._display"
          :src="activity.assignedTo.avatar?activity.assignedTo.avatar.url:''"
          class="my-auto"
      />

      <!--      <b-avatar-->
      <!--          variant="light-primary"-->
      <!--          :text="userAvatarText(log.by)"-->
      <!--          :src="log.by.avatar?log.by.avatar.url:''"-->
      <!--          class=""-->
      <!--      />-->
    </div>

    <div
        class="w-100 py-50 text-truncate"
    >
      <h6 class="text-truncate"
          id="tooltip-target-1"
          v-html="$t('activityTitle', {
                      date: capitalize(displayDate(activity.dueDate)),
                      type: $t(activity.type),
                      user: activity.assignedTo.firstName + ' ' +activity.assignedTo.name
                    })"
      >
      </h6>

      <b-tooltip target="tooltip-target-1"
                 triggers="hover">
        <span v-html="$t('activityTitle', {
                      date: capitalize(displayDate(activity.dueDate)),
                      type: $t(activity.type),
                      user: activity.assignedTo.firstName + ' ' +activity.assignedTo.name
                    })"/>
      </b-tooltip>

      <small class="mb-0">

        <button-done
            :withBorder="false"
            :withIcon="true"
            :size="'sm'"
            @click="$emit('doneActivity')"
        />

        <button-edit
            :withBorder="false"
            :withIcon="true"
            :size="'sm'"
            @click="$emit('editPlanifiedActivity')"
        />

        <button-cancel
            :withBorder="false"
            :withIcon="true"
            :size="'sm'"
            @click="$emit('cancelPlanifiedActivity')"
        />

      </small>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, userAvatarText } from '@/utils/filter'

import moment from 'moment'
import ButtonDone from '@/components/button/Done'
import ButtonEdit from '@/components/button/Edit'
import ButtonCancel from '@/components/button/Cancel'

export default {
  components: {
    ButtonDone,
    ButtonEdit,
    ButtonCancel
  },
  props: {
    activity: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const displayDate = (date) => {
      return moment(date).fromNow()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      userAvatarText,

      // Data

      // Computed

      // Methods
      displayDate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>