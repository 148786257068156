<template>
  <div>
    <validation-observer #default="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitValidatedNoteLocal)">
        <form-note :note="note"/>
<!--        <b-button-->
<!--            variant="relief-primary"-->
<!--            size="sm"-->
<!--            type="submit"-->
<!--        >-->
<!--          {{ capitalize($t('send')) }}-->
<!--        </b-button>-->
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import { ValidationObserver } from 'vee-validate'

import FormNote from '@/components/form/Note'

export default {
  components: {
    ValidationObserver,
    FormNote
  },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const submitNoteFormRef = ref(null)
    const note = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const submitValidatedNoteLocal = () => {
      emit('submitValidatedNoteLocal', note.value)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      submitNoteFormRef,
      note,

      // Computed

      // Methods
      submitValidatedNoteLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>