<template>
  <b-row>

    <b-col cols="12">
      <b-card>
        <form-filter
            :from.sync="from"
            :to.sync="to"
            :phases="workflow.phases"
            :selectedPhases.sync="selectedPhases"
        />
      </b-card>
    </b-col>

    <b-col md="6">
      <indicator-sales
          :workflow="workflow"
          :from="from"
          :to="to"
          :selectedPhases="selectedPhases"
      />
    </b-col>
    <b-col md="6">
      <indicator-purchases
          :workflow="workflow"
          :from="from"
          :to="to"
          :selectedPhases="selectedPhases"
      />
    </b-col>
    <b-col md="6">
      <indicator-h-r
          :workflow="workflow"
          :from="from"
          :to="to"
          :selectedPhases="selectedPhases"
      />
    </b-col>
    <b-col md="6">
      <indicator-profitability
          :workflow="workflow"
          :from="from"
          :to="to"
          :selectedPhases="selectedPhases"
      />
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { getCurrentFinancialYear} from '@/utils/utils'

import FormFilter from './components/_formFilter'
import IndicatorProfitability from './components/Profitability'
import IndicatorSales from './components/Sales'
import IndicatorPurchases from './components/Purchases'
import IndicatorHR from './components/HR'
import moment from 'moment'

export default {
  components: {
    FormFilter,
    IndicatorSales,
    IndicatorPurchases,
    IndicatorProfitability,
    IndicatorHR,
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const from = ref(null)
    const to = ref(null)
    const selectedPhases = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    // const phases = computed( () => {
    //   return props.workflow
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    from.value = getCurrentFinancialYear().start
    to.value = getCurrentFinancialYear().end

    return {
      // Components

      // Data
      from,
      to,
      selectedPhases,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>