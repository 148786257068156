<template>
  <generic-indicator-card
      :toDonut="toDonut"
      :isDonutCurrencies="true"
      :from="from"
      :to="to"
      :activate-modal="true"
  >
    <template #header>
      <h4 v-b-tooltip.hover.bottom="capitalize($t('potentialExpenses'))">
        {{ $t('Purchases') }}
        <br/>
        {{ currency(workflow.potentialExpenses, true) }}
        <br/>
        <div class="text-truncate">{{ capitalize($t('potentialExpenses')) }}</div>
      </h4>
    </template>

    <template #indicators>
      <b-row>

        <!--          Budget-->
        <b-col
            md="6"
            v-b-tooltip.hover.bottom="capitalize($t('budget:')) + ' '+ currency(currentPurchased, true) + ' /' +  currency(workflow.potentialExpenses, true)"
        >
          <label>
            {{ capitalize($t('budget:')) }}&nbsp;{{ currency(currentPurchased, true) }} / {{
              currency(workflow.potentialExpenses, true)
            }}
          </label>
          <b-progress
              v-model="currentPurchased"
              :max="workflow.potentialExpenses"
              animated
              variant="primary"
              :class="'progress-bar-primary'"
          />
        </b-col>


      </b-row>
    </template>

    <template #indicatorDetails>
      <purchases-indicator-details
          :orders="currentPurchases.orderEntities"
          :incomingInvoices="currentPurchases.incomingInvoiceEntities"
      />
    </template>

  </generic-indicator-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'
import { useIndicators } from '../../../../../components/indicators/useIndicators'

import useAPI from '@/utils/useAPI'
import GenericIndicatorCard from './_GenericCard'
import PurchasesIndicatorDetails from '@/components/indicatorDetails/PurchasesIndicators'
import moment from 'moment'
import i18n from '@/libs/i18n'
import store from '@/store'

export default {
  components: {
    GenericIndicatorCard,
    PurchasesIndicatorDetails
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String
    },
    to: {
      type: String
    },
    selectedPhases: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    // const { getUserContract } = useAPI()

    const currentPurchased = computed(() => {
      return currentPurchases.value.total
    })

    const currentOrdered = computed(() => {
      return currentPurchases.value.orderApprovedNotOrderer
    })

    const currentPurchases = computed(() => {
      return getCurrentPurchases(
          props.workflow,
          props.from,
          props.to,
          props.selectedPhases)
    })

    const toDonut = computed(() => {
      return {
        labels: [
          capitalize(i18n.tc('offer', 2)),
          capitalize(i18n.tc('order', 2)),
          capitalize(i18n.tc('invoice', 2)),
          capitalize(i18n.t('time')),
        ],
        values: [
          currentPurchases.value.orderApprovedNotOrderer,
          currentPurchases.value.orderOrdered,
          currentPurchases.value.incomingInvoices,
          currentPurchases.value.time,
        ],
        total: currency(currentPurchases.value.total, true)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      getCurrentPurchases
    } = useIndicators()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data

      // Computed
      currentPurchased,
      currentOrdered,
      currentPurchases,
      toDonut,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>