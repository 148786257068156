<template>
  <search-filter
      :items="items"
      keyTitleForAlphabeticalSort="title"
      :selectedFilters="[]"
      :selectedView="'list'"
      :excludedKeys="[]"
      :tableColumns="tableColumns"
      :isActionColumnEnabled="true"
      :readOnly="true"
      @columnClick="false"
  >
    <!--      result infos-->
    <template #resultInfos="{items}">
      ({{ items.length }})
      <span>{{ currency(getPreTaxAmounts(items)) }} HT</span>
    </template>

    <!--      List view-->

    <!--      Type-->
    <template #listView_cell_type="{item}">
      {{ capitalize($tc(item.type)) }}
    </template>

    <!--      Supplier-->
    <template #listView_cell_supplier="{item}">
      <span v-if="item.supplier != null">{{ capitalize($tc(item.supplier._display)) }}</span>
    </template>

    <!--      Date-->
    <template #listView_cell_date="{item}">
      {{ item.date|moment('L') }}
    </template>

    <!--      Actions-->
    <template #listView_cell_actions="{item}">
      <div class="text-nowrap">

        <!--          View-->
        <router-link
            :to="$router.resolve({ name: 'Order view', params: { orderId: item.entity.id } }).href"
            target="_blank"
            class="text-decoration-none"
        >
          <icon
              v-b-tooltip.hover.left="$t('View')"
              icon="external-link-alt"
              class="cursor-pointer"/>
        </router-link>

        <!--          Download-->
        <icon
            v-if="item.type == 'invoice'"
            @click.native.stop="downloadIncomingInvoice(item.entity)"
            v-b-tooltip.hover.left="capitalize($t('Download'))"
            icon="download"
            class="cursor-pointe mx-1"/>
      </div>
    </template>

  </search-filter>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '@/utils/filter'
import { useIncomingInvoices } from '../../views/purchases/incomingInvoices/useIncomingInvoices'

import SearchFilter from '../searchFilter/SearchFilter'
import i18n from '@/libs/i18n'

export default {
  components: {
    SearchFilter
  },
  props: {
    orders: {
      type: Array,
      default: () => []
    },
    incomingInvoices: {
      type: Array,
      default: () => []
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const tableColumns = ref([
      { key: 'type', label: i18n.t('type'), sortable: true },
      { key: 'supplier', label: i18n.t('supplier'), sortable: true },
      { key: 'date', label: i18n.t('date'), sortable: true },
      { key: 'preTaxAmount', label: i18n.t('preTaxAmount'), sortable: true },
      { key: 'totalAmount', label: i18n.t('totalAmount'), sortable: true },
      { key: 'actions', label: i18n.tc('action', 2), sortable: false }
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const items = computed(() => {
      let output = []

      props.orders.forEach(o => {
        output.push({
          type: 'order',
          supplier: o.supplierCompany,
          date: o.logs[0].at,
          preTaxAmount: o.preTaxAmount,
          totalAmount: o.totalAmount,
          entity: o
        })
      })

      props.incomingInvoices.forEach(o => {
        output.push({
          type: 'invoice',
          supplier: o.supplierCompany,
          date: o.billingDate,
          preTaxAmount: o.preTaxAmount,
          totalAmount: o.totalAmount,
          entity: o
        })
      })

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadIncomingInvoice } = useIncomingInvoices()

    const getPreTaxAmounts = (concernedItems) => {
      let preTaxAmounts = 0

      concernedItems.forEach(item => {
        if (item.type == 'order') {
          if (item.entity.number != null) {
            if (
                item.entity.signedDocumentNumber != null ||
                item.entity.signedDocumentDate != null ||
                item.entity.signedDocument != null
            ) {
              // Order ordered
              item.entity.products.forEach(p => {
                if (p._remaining > 0) {
                  preTaxAmounts += parseFloat(p._remaining) * p.preTaxAmount
                }

                if (p.childs.length) {
                  p.childs.forEach(invoicedProduct => {
                    preTaxAmounts += invoicedProduct.preTaxAmount
                  })
                }
              })
            } else {
              // Order approuved not ordered
              preTaxAmounts += item.preTaxAmount
            }
          }
        } else if (item.type == 'invoice') {
          item.entity.products.forEach(p => {
            if (p.parent == null) {
              preTaxAmounts += p.preTaxAmount
            }
          })
        }
      })

      return preTaxAmounts
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      currency,

      // Data
      tableColumns,

      // Computed
      items,

      // Methods
      downloadIncomingInvoice,
      getPreTaxAmounts,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>