<template>
  <b-row>
    <!--    From-->
    <b-col md="3">
      <date-field
          :model.sync="fromLocal"
          :name="'startDate'"
      />
    </b-col>

    <!--    To-->
    <b-col md="3">
      <date-field
          :model.sync="toLocal"
          :name="'endDate'"
      />
    </b-col>

    <!--    Phases-->
    <b-col md="6">
      <select-field
          :model.sync="selectedPhasesLocal"
          :name="'phases'"
          :placeholder="$t('Select phases')"
          :options="phases"
          :label="'name'"
          :multiple="true"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import DateField from '@/components/input/Date'
import SelectField from '@/components/input/Select'

export default {
  components: {
    DateField,
    SelectField
  },
  props: {
    from: {},
    to: {},
    phases: {
      type: Array,
      default: () => []
    },
    selectedPhases: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const fromLocal = ref(props.from)
    const toLocal = ref(props.to)
    const selectedPhasesLocal = ref(props.selectedPhases)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(fromLocal, (val) => {
      emit('update:from', val)
    })
    watch(toLocal, (val) => {
      emit('update:to', val)
    })
    watch(selectedPhasesLocal, (val) => {
      emit('update:selectedPhases', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      fromLocal,
      toLocal,
      selectedPhasesLocal,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    from: function (val) {
      this.fromLocal = val
    },
    to: function (val) {
      this.toLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>