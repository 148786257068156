<template>
  <div>
    <b-row>
      <b-col cols="12"
             class="d-flex justify-content-between">

        <div class="w-100">
          <field-textarea
              :model.sync="note.comment"
              :name="$tc('comment',2)"
              :placeholder="'comment'"
              :displayLabel="false"
              style="overflow: auto; max-height: 135px;"
          />
        </div>
        <div class="">
          <button-send
              :disabled="note.comment=='' || note.comment == null ||note.comment == '<p></p>'"
              type="submit"
              class="ml-50"
          />

          <button-join
              @click.native="uploadModalShow=true"
              class="ml-50"
          />

        </div>

      </b-col>
    </b-row>

    <b-row>
      <b-col
          :key="componentKey"
          cols="12">

        <card-document
            v-for="(document, index) in note.files"
            :key="index"
            :document="document"
            @delete="deleteFile(index)"
        />


      </b-col>
    </b-row>


    <!-- modal upload-->
    <modal-upload
        :singleFile.sync="newFile"
        :documentType="'monitoring'"
        :isOpen.sync="uploadModalShow"
        ref="modalUploadRef"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldTextarea from '@/components/input/Textarea'
import ModalUpload from '@/components/prompt/Upload'
import CardDocument from '@/components/card/Document'
import ButtonSend from '@/components/button/Send'
import ButtonJoin from '@/components/button/Join'

export default {
  components: {
    FieldTextarea,
    ModalUpload,
    CardDocument,
    ButtonSend,
    ButtonJoin
  },
  props: {
    note: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const uploadModalShow = ref(false)
    const newFile = ref(null)
    const componentKey = ref(0)
    const modalUploadRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newFile, (val) => {
      // console.log(val)
      if (val != null) {
        if ('files' in props.note) {
          props.note.files.push(val)
        } else {
          props.note.files = [val]
        }

        uploadModalShow.value = false
        resetFile()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetFile = () => {
      newFile.value = JSON.parse(JSON.stringify(null))
      modalUploadRef.value.resetUpload()
    }

    const deleteFile = (index) => {
      props.note.files.splice(index, 1)
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      uploadModalShow,
      newFile,
      componentKey,
      modalUploadRef,

      // Computed

      // Methods
      deleteFile,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>