var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-filter',{attrs:{"items":_vm.items,"keyTitleForAlphabeticalSort":"title","selectedFilters":[],"selectedView":'list',"excludedKeys":[],"tableColumns":_vm.tableColumns,"isActionColumnEnabled":true,"readOnly":true},on:{"columnClick":false},scopedSlots:_vm._u([{key:"resultInfos",fn:function(ref){
var items = ref.items;
return [_vm._v(" ("+_vm._s(items.length)+") "),_c('span',[_vm._v(_vm._s(_vm.currency(_vm.getPreTaxAmounts(items)))+" HT")])]}},{key:"listView_cell_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalize(_vm.$tc(item.type)))+" ")]}},{key:"listView_cell_customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer._display)+" ")]}},{key:"listView_cell_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date,'L'))+" ")]}},{key:"listView_cell_actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.$router.resolve({ name: 'Offer view', params: { offerId: item.entity.id } }).href,"target":"_blank"}},[_c('icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('View')),expression:"$t('View')",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointer",attrs:{"icon":"external-link-alt"}})],1),(item.type == 'invoice')?_c('icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.capitalize(_vm.$t('Download'))),expression:"capitalize($t('Download'))",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointe mx-1",attrs:{"icon":"download"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.downloadOutgoingInvoice(item.entity)}}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }