<template>
  <b-row v-if="workflow">

    <b-col cols="12">
      <div class="d-flex justify-content-between">
        <h1 class="text-truncate mt-50">{{ workflow.opportunity }}</h1>

        <div v-if="workflow.closingDate">
          <span>{{ workflow.closingDate|moment('L') }}</span>
        </div>

        <div v-if="contact != null" class="text-right">
          {{ contact._display }}
        </div>
      </div>
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

export default {
  components: {},
  props: {
    workflow: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const contact = computed(() => {
      if (props.workflow.customerCompany != null) {
        return props.workflow.customerCompany
      } else {
        return props.workflow.customerIndividual
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      contact,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>